
import styles from '../styles/chat.module.scss'
import { ReactElement, useMemo, useEffect, useRef, useState, useCallback } from 'react'
import Router, { useRouter } from 'next/router';
import http from "../http";
import Loading from '../components/loading';
import Tips from '../components/tips';
import { useManualServerSentEvents } from '../hook/useManualServerSentEvents';
import TypingEffect from '../components/TypingEffect';
import Setting from '../components/Setting'
import { useTheme } from '../context/ThemeContext';
import CodeTree, { CodeFileType, TreeData } from '../components/tree/CodeTree';
import Modal from '../components/Modal/Modal';
import CodePreview from '../components/CodePreview/CodePreview';
import UpLoad, { accept } from '../components/UpLoad/UpLoad';
import LoadingIcon from '../components/Icon/LoadingIcon';
import { version } from '../version'
import ChatTextArea from './common/ChatTextArea';
import ChatCreatContent from './common/ChatCreatContent';
import BottomLayer from './common/BottomLayer';
import { ShowMessage } from '../components/ShowMessage/ShowMessage';
export default function Index() {
    const router = useRouter();
    const { github_id } = router.query;
    const markdown = useRef('');
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [chatList, setChatList] = useState([])
    const [text, setText] = useState('');
    const thread_id = useRef('' as any)
    const [chatContent, setChatContent] = useState([] as any)
    const [currentList, setCurrentList] = useState('' as any)
    const [userInfo, setUserInfo] = useState({} as any)
    const [fileName, setFileName] = useState('')
    const file_id = useRef('' as any)
    const [settingShow, setSettingShow] = useState(false)
    const { theme, toggleTheme } = useTheme();
    const [stop, setStop] = useState(false)
    const stopEnter = useRef(false)
    const [chatpdfTips, setChatpdfTips] = useState(true)
    const chatContainerRef = useRef({} as any);
    const [flag, setFlag] = useState(false)
    const [autoScroll, setAutoScroll] = useState(true);
    const typeRef = useRef('' as any);
    const [cancleTag, setCancleTag] = useState(false);
    const [visit_page, setVisit_page] = useState(0)
    const [isShowCode, setIsShowCode] = useState(false) // 是否展示代码树
    const [isOpen, setIsOpen] = useState(false)
    const [selectNode, setSelectNode] = useState<TreeData>()
    const [upLoading, setUpLoading] = useState(false)
    const handleScroll = useCallback(() => {
        if (!chatContainerRef.current) return;
        const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
        const isAtBottom = scrollHeight - scrollTop - clientHeight < 100;
        setAutoScroll(isAtBottom);
    }, []);

    const toggleSidebar = () => {
        setIsSidebarCollapsed(!isSidebarCollapsed);
    };

    useEffect(() => {
        // createThread()
        // getChatList()
        // 页面刷新之后取消threadId的连接
        const ids = JSON.parse(localStorage.getItem('threadIds') || '[]')
        ids.forEach(async (id: string) => {
            await reLoadCancleChat(id)
        });
        localStorage.setItem("threadIds", JSON.stringify([]))

        getInfo()
    }, [])

    useEffect(() => {
        //debugger
        if (router.isReady) {
            // 路由参数已准备好，可以进行操作
            router.query.github_id && setSettingShow(true)
            if (!router.query.thread_id) {
                createThread()
            } else {
                getChatList()
                setCurrentList(router.query.thread_id)
                getChatLog(router.query.thread_id)
                thread_id.current = router.query.thread_id
            }
        }
    }, [router.isReady])

    useEffect(() => {
        const chatContainer = chatContainerRef.current;
        if (chatContainer && !chatpdfTips) {
            chatContainer.addEventListener('scroll', handleScroll);

            return () => {
                chatContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, [chatpdfTips, handleScroll]);
    useEffect(() => {
        if (chatContainerRef.current && autoScroll) {
            const { scrollHeight, clientHeight } = chatContainerRef.current;
            chatContainerRef.current.scrollTop = scrollHeight - clientHeight;
        }
    }, [chatContent, autoScroll]); // 在 chatContent 或 autoScroll 变化时触发
    const handleInputChange = (e: { target: any; }) => {
        const textarea = e.target;
        textarea.style.height = 'auto'; // 先重置高度
        textarea.style.height = `${textarea.scrollHeight}px`; // 根据内容调整高度
        markdown.current = textarea.value;
        setText(textarea.value);
        if (!textarea.value) {
            textarea.style.height = '24px'; // 恢复到初始高度
        }
    };
    const clearFileId = () => {
        file_id.current = null
        setFileName('')
    }
    const getInfo = () => {
        setIsLoading(true)
        http.post(`/api/v1/account/info`).then(
            (data: any) => {
                setIsLoading(false)
                if (data.status) {
                    if (data.data.subscription_expired) {
                        const [datePart] = data.data.subscription_expired.split(' ');
                        data.data.subscription_expired = datePart;
                    }
                    setUserInfo(data.data)
                } else {
                    ShowMessage.error(data.message)
                }
            }
        );
    }
    const resetFn = () => {
        setStop(false)
        stopEnter.current = false
        markdown.current = ''
    }
    const {
        messages,
        startFetching,
        stopFetching,
        setMessages,
        treeData,
        codeData,
        responseState,
        isoutputing,
        setIsOutputing
    } = useManualServerSentEvents('/api/v1/chat/chat', { file_id: file_id.current, thread_id: thread_id.current, message: markdown.current }, setIsLoading, clearFileId, setStop, setFlag, getInfo, resetFn);
    const giToBottom = () => {
        // console.log(autoScroll,chatContainerRef.current.scrollHeight,chatContainerRef.current.scrollTop)
        if (chatContainerRef.current && autoScroll) {
            const { scrollHeight, clientHeight } = chatContainerRef.current;
            chatContainerRef.current.scrollTop = scrollHeight + stop ? 40 : 0;
        }

    }
    // Combine messages and replace '\n\n' with HTML line break '<br /><br />'
    const combinedMessages = useMemo(() => {
        // setStop(false)

        let str = ''
        // messages.map((message: any) => message.content ? str += message.content : null);
        chatContent[chatContent.length - 1] = { text: messages, user_type: 'assistant' }
        setChatContent([...chatContent])
        // return str.replace(/\n\n/g, '<br /><br />');
        return str
    }, [messages]);

    useEffect(() => {
        if (responseState === 500) {
            reLoadCancleChat(thread_id.current)
            setCancleTag(true) // 显示已取消
        }
    }, [responseState])

    // 创建对话
    const createThread = () => {
        // debugger
        setStop(false)
        http.post(`/api/v1/chat/thread`).then(
            (data: any) => {
                setIsLoading(false)
                //debugger
                // this.ControlMessage('加载完成', true);
                if (data.status) {
                    if (data.code == 402) {
                        setFlag(true)
                        getInfo()
                        return
                    }
                    thread_id.current = data.thread_id
                    Router.push(`/?thread_id=${data.thread_id}`)
                    setCurrentList(thread_id.current)

                } else {
                    ShowMessage.error(data.message)
                }
                getChatList()

            }, (err) => {
                console.log(err)
                setIsLoading(false)
            }
        ).catch(res => {

        })
    }
    // 聊天
    const createChat = () => { //点击发送
        if (upLoading) { //上传过程中不能点击发送
            return;
        }
        if (flag) {
            setText('')
            ShowMessage.error('您的今日试用对话机会已用完，请明天继续使用，或者进行会员订阅')
            return
        }
        if (markdown.current.trim() == '') {
            ShowMessage.error('请输入您需要解读的内容')
            return
        }
        // 记录当前thread_id
        const ids = JSON.parse(localStorage.getItem('threadIds') || '[]')
        localStorage.setItem("threadIds", JSON.stringify([...ids, thread_id.current]))

        typeRef.current && typeRef.current.setStopTimer()
        setChatpdfTips(false)
        setStop(true)
        setAutoScroll(true);
        setChatContent([...chatContent, { text: markdown.current, user_type: "user", file_name: fileName }, { text: 'loading', user_type: 'assistant' }])
        setText('')
        setCancleTag(false)
        startFetching({ file_id: file_id.current, thread_id: thread_id.current, message: markdown.current })

    }
    // 获取对话列表
    const getChatList = () => {
        setIsLoading(true)
        http.get(`/api/v1/chat/chatlist`).then(
            (data: any) => {
                setIsLoading(false)
                if (data.status) {
                    setChatList(data.data)
                } else {
                    ShowMessage.error(data.message)
                }

            }, (err) => {
                console.log(err)
                setIsLoading(false)
            }
        );
    }

    // 获取聊天历史
    const getChatLog = async (threadId: any) => {
        // let res: any = await cancleChat()
        // if (!res) { return }
        Router.push(`/?thread_id=${threadId}`)
        thread_id.current = threadId

        setText('')
        typeRef.current && typeRef.current.setStopTimer()
        setCancleTag(false)
        stopFetching()
        resetFn()
        setIsLoading(true)
        setSettingShow(false)
        http.get(`/api/v1/chat/chat_history/?thread_id=${threadId}`).then(
            (data: any) => {
                setIsLoading(false)
                if (data.status) {
                    if (data.data.length == 0) {
                        setChatpdfTips(true)
                    }
                    else {
                        setChatpdfTips(false)
                    }
                    setChatContent(data.data)
                } else {
                    ShowMessage.error(data.message)
                }
            }
        );
    }

    const createNewChat = async () => {
        setIsLoading(true)
        setIsShowCode(false)
        setCancleTag(false)
        setChatpdfTips(true)
        setSettingShow(false)
        setChatContent([])
        setText('')
        // setMarkdown('')
        markdown.current = ''
        typeRef.current && typeRef.current.setStopTimer()
        let res: any = await cancleChat()
        // debugger
        if (!res) { return }
        createThread()

    }
    const uploadFile = (e: any) => {
        e.preventDefault();
        // 检查文件后缀是否合法
        const file = e.target.files?.[0];
        if (!file) {
            ShowMessage.error('请选择一个文件')
            return;
        }
        if (file.size > 220 * 1024 * 1024) {
            ShowMessage.error('最大只能上传220MB')
            return;
        }
        const fileExtension = file.name.split('.').pop()?.toLowerCase();
        if (!accept.includes(`.${fileExtension}`)) {
            ShowMessage.error(`只能上传${accept.toString()}文件`)
            return;
        }

        setUpLoading(true)
        let formData = new FormData()
        setFileName(file.name)
        formData.append('file', e.target.files[0])
        http.post(`/api/v1/chat/file?thread_id=${thread_id.current}`, formData).then((res: any) => {
            if (res.openai_file_id) {
                ShowMessage.success('上传成功')
                file_id.current = res.openai_file_id
            } else {
                ShowMessage.error('上传失败')
                clearFileId()
            }
            setUpLoading(false)
        }).catch(() => {
            ShowMessage.error('上传失败')
            clearFileId()
        }).finally(() => {
            setUpLoading(false)
        })
    }
    const deleteChat = (uuid: any) => {
        setIsLoading(true)
        http.delete(`/api/v1/chat/chat/${uuid}`).then(
            (data: any) => {
                if (data.status) {
                    getChatList()
                } else {
                    ShowMessage.error(data.message)
                }
                setIsLoading(false)
            }
        );
    }
    const keyupFun = (e: any) => {
        const { code, shiftKey } = e
        if (upLoading) { //上传过程中不能发送
            return;
        }
        if (code == 'Enter' && !shiftKey && !e.nativeEvent.isComposing) {
            if (flag) {
                ShowMessage.error('您的今日试用对话机会已用完，请明天继续使用，或者进行会员订阅')
                setText('')
                return
            }
            if (stopEnter.current) return
            if (markdown.current.trim() === '') {
                ShowMessage.error('请输入您需要解读的内容')
                return
            }
            setChatpdfTips(false);
            setStop(true)
            typeRef.current && typeRef.current.setStopTimer()
            setAutoScroll(true);
            stopEnter.current = true
            setChatContent((prevChatContent: any) => [
                ...prevChatContent,
                { text: markdown.current, user_type: 'user', file_name: fileName },
                { text: 'loading', user_type: 'assistant' },
            ]);
            setText('')
            setCancleTag(false)
            startFetching({ file_id: file_id.current, thread_id: thread_id.current, message: markdown.current });
        }
    }

    const cancleFn = () => { //取消发送
        // debugger
        if (typeRef.current) { // 清除定时器，存在typeRef说明已经接口返回问题答案
            typeRef.current.clearTimeout();
        }
        if (chatContent[chatContent.length - 1].text === 'loading') { //点击取消如果最后一条是内容还是loading，说明答案未返回，直接修改成取消
            chatContent[chatContent.length - 1].text = '已取消'
            setChatContent([...chatContent])
        }
        cancleChat()
        stopFetching();
        resetFn();
        setCancleTag(true)


    }
    const cancleChat = () => { //取消与后端的聊天
        return new Promise((resolve, reject) => {
            http.post('api/v1/chat/cancel', { thread_id: thread_id.current }).then((data: any) => {
                if (data.status) {
                    resolve(true)
                } else {
                    resolve(false)
                    ShowMessage.error(data.message)
                }
            }).finally(() => {
                setIsOutputing(false)
            })
        })
    }
    // 需要传id
    const reLoadCancleChat = (id: string) => { //取消与后端的聊天
        return new Promise((resolve, reject) => {
            http.post('api/v1/chat/cancel', { thread_id: id }).then((data: any) => {
                if (data.status) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            }).finally(() => {
                setIsOutputing(false)
            })
        })
    }
    const showCode = () => {
        setIsShowCode(!isShowCode)
    }
    const handleSelect = (node: TreeData) => {
        setSelectNode(node)
        setIsOpen(true)
    }

    const handleCopy = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            ShowMessage.success("复制成功")
        }).catch(err => {
            console.error(err);
        });
    }
    return (

        <div className={styles.chatpdf}>
            <div className={`${styles.pannel} ${isSidebarCollapsed ? styles.collapsed : ''}`}>
                <div className={styles.sidebarControls}>
                    <div className={styles.toggleSidebar} onClick={toggleSidebar}>
                        {isSidebarCollapsed ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" className="icon-xl-heavy">
                                <path fill="currentColor" fill-rule="evenodd" d="M8.857 3h6.286c1.084 0 1.958 0 2.666.058.729.06 1.369.185 1.961.487a5 5 0 0 1 2.185 2.185c.302.592.428 1.233.487 1.961.058.708.058 1.582.058 2.666v3.286c0 1.084 0 1.958-.058 2.666-.06.729-.185 1.369-.487 1.961a5 5 0 0 1-2.185 2.185c-.592.302-1.232.428-1.961.487C17.1 21 16.227 21 15.143 21H8.857c-1.084 0-1.958 0-2.666-.058-.728-.06-1.369-.185-1.96-.487a5 5 0 0 1-2.186-2.185c-.302-.592-.428-1.232-.487-1.961C1.5 15.6 1.5 14.727 1.5 13.643v-3.286c0-1.084 0-1.958.058-2.666.06-.728.185-1.369.487-1.96A5 5 0 0 1 4.23 3.544c.592-.302 1.233-.428 1.961-.487C6.9 3 7.773 3 8.857 3M6.354 5.051c-.605.05-.953.142-1.216.276a3 3 0 0 0-1.311 1.311c-.134.263-.226.611-.276 1.216-.05.617-.051 1.41-.051 2.546v3.2c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 0 0 1.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051h.6V5h-.6c-1.137 0-1.929 0-2.546.051M11.5 5v14h3.6c1.137 0 1.929 0 2.546-.051.605-.05.953-.142 1.216-.276a3 3 0 0 0 1.311-1.311c.134-.263.226-.611.276-1.216.05-.617.051-1.41.051-2.546v-3.2c0-1.137 0-1.929-.051-2.546-.05-.605-.142-.953-.276-1.216a3 3 0 0 0-1.311-1.311c-.263-.134-.611-.226-1.216-.276C17.029 5.001 16.236 5 15.1 5zM5 8.5a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1M5 12a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1" clip-rule="evenodd"></path>
                            </svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" className="icon-xl-heavy">
                                <path fill="currentColor" fill-rule="evenodd" d="M8.857 3h6.286c1.084 0 1.958 0 2.666.058.729.06 1.369.185 1.961.487a5 5 0 0 1 2.185 2.185c.302.592.428 1.233.487 1.961.058.708.058 1.582.058 2.666v3.286c0 1.084 0 1.958-.058 2.666-.06.729-.185 1.369-.487 1.961a5 5 0 0 1-2.185 2.185c-.592.302-1.232.428-1.961.487C17.1 21 16.227 21 15.143 21H8.857c-1.084 0-1.958 0-2.666-.058-.728-.06-1.369-.185-1.96-.487a5 5 0 0 1-2.186-2.185c-.302-.592-.428-1.232-.487-1.961C1.5 15.6 1.5 14.727 1.5 13.643v-3.286c0-1.084 0-1.958.058-2.666.06-.728.185-1.369.487-1.96A5 5 0 0 1 4.23 3.544c.592-.302 1.233-.428 1.961-.487C6.9 3 7.773 3 8.857 3M6.354 5.051c-.605.05-.953.142-1.216.276a3 3 0 0 0-1.311 1.311c-.134.263-.226.611-.276 1.216-.05.617-.051 1.41-.051 2.546v3.2c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 0 0 1.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051h.6V5h-.6c-1.137 0-1.929 0-2.546.051M11.5 5v14h3.6c1.137 0 1.929 0 2.546-.051.605-.05.953-.142 1.216-.276a3 3 0 0 0 1.311-1.311c.134-.263.226-.611.276-1.216.05-.617.051-1.41.051-2.546v-3.2c0-1.137 0-1.929-.051-2.546-.05-.605-.142-.953-.276-1.216a3 3 0 0 0-1.311-1.311c-.263-.134-.611-.226-1.216-.276C17.029 5.001 16.236 5 15.1 5zM5 8.5a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1M5 12a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1" clip-rule="evenodd"></path>
                            </svg>
                        )}
                    </div>
                    {!isSidebarCollapsed && (
                        <div className={styles.logoContainer}>
                            <div className={styles.text_logo}></div>
                        </div>
                    )}
                    {/* 切换项目树按钮 */}
                    <div className={styles.btns}>
                        {!isSidebarCollapsed && !!codeData.branch_name && (<div className={styles.switchBtn} onClick={showCode}>
                            <div title={!isShowCode ? "目录树视图" : "返回对话列表"}>
                                {isShowCode ? <svg className="icon" viewBox="0 0 1706 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5210" width="32" height="32"><path d="M1176.50680606 206.4796294H458.53393514a48.37405887 48.37405887 0 0 0-48.37405887 48.37405887v5.09200598a48.37405887 48.37405887 0 0 0 48.37405887 48.37405887h717.97287092A48.37405887 48.37405887 0 0 0 1224.88086493 259.94569425v-5.09200598A48.37405887 48.37405887 0 0 0 1176.50680606 206.4796294z m0 254.60030903H458.53393514a48.37405887 48.37405887 0 0 0-48.37405887 48.37405828v5.09200658a48.37405887 48.37405887 0 0 0 48.37405887 48.37405828h717.97287092A48.37405887 48.37405887 0 0 0 1224.88086493 514.54600329v-5.09200658A48.37405887 48.37405887 0 0 0 1176.50680606 461.07993843z m0 254.60030844H458.53393514a48.37405887 48.37405887 0 0 0-48.37405887 48.37405888v5.09200597a48.37405887 48.37405887 0 0 0 48.37405887 48.37405888h717.97287092A48.37405887 48.37405887 0 0 0 1224.88086493 769.14631172v-5.09200597A48.37405887 48.37405887 0 0 0 1176.50680606 715.68024687zM257.39969097 206.4796294a50.92006157 50.92006157 0 1 0 50.92006216 50.92006157A50.92006157 50.92006157 0 0 0 257.39969097 206.4796294z m0 254.60030903a50.92006157 50.92006157 0 1 0 50.92006216 50.92006157A50.92006157 50.92006157 0 0 0 257.39969097 461.07993843z m0 254.60030844a50.92006157 50.92006157 0 1 0 50.92006216 50.92006216A50.92006157 50.92006157 0 0 0 257.39969097 715.68024687z" p-id="5211" fill="#ffffff"></path></svg>
                                    :
                                    <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4848" width="24" height="24"><path d="M705.5 554.47h215a21.44 21.44 0 0 0 21.5-21.24V405.82a21.43 21.43 0 0 0-21.51-21.22h-215A21.43 21.43 0 0 0 684 405.82V435H289V299.65h115.49A21.43 21.43 0 0 0 426 278.42V151a21.43 21.43 0 0 0-21.51-21.23h-301A21.42 21.42 0 0 0 82 151v127.42a21.42 21.42 0 0 0 21.5 21.23h115.57v501.67c0 23.49 19.21 42.47 43 42.47H684V873a21.44 21.44 0 0 0 21.51 21.23h215A21.44 21.44 0 0 0 942 873V745.58a21.44 21.44 0 0 0-21.51-21.24h-215A21.44 21.44 0 0 0 684 745.58v29.2H289V504h395v29.19a21.44 21.44 0 0 0 21.51 21.24z" p-id="4849" fill="#ffffff"></path></svg>
                                }

                            </div>
                        </div>)}
                        <div className={styles.editIcon} onClick={createNewChat} title={"新建对话"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24" className="icon-xl-heavy"><path d="M15.673 3.913a3.121 3.121 0 1 1 4.414 4.414l-5.937 5.937a5 5 0 0 1-2.828 1.415l-2.18.31a1 1 0 0 1-1.132-1.13l.311-2.18A5 5 0 0 1 9.736 9.85zm3 1.414a1.12 1.12 0 0 0-1.586 0l-5.937 5.937a3 3 0 0 0-.849 1.697l-.123.86.86-.122a3 3 0 0 0 1.698-.849l5.937-5.937a1.12 1.12 0 0 0 0-1.586M11 4A1 1 0 0 1 10 5c-.998 0-1.702.008-2.253.06-.54.052-.862.141-1.109.267a3 3 0 0 0-1.311 1.311c-.134.263-.226.611-.276 1.216C5.001 8.471 5 9.264 5 10.4v3.2c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 0 0 1.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051h3.2c1.137 0 1.929 0 2.546-.051.605-.05.953-.142 1.216-.276a3 3 0 0 0 1.311-1.311c.126-.247.215-.569.266-1.108.053-.552.06-1.256.06-2.255a1 1 0 1 1 2 .002c0 .978-.006 1.78-.069 2.442-.064.673-.192 1.27-.475 1.827a5 5 0 0 1-2.185 2.185c-.592.302-1.232.428-1.961.487C15.6 21 14.727 21 13.643 21h-3.286c-1.084 0-1.958 0-2.666-.058-.728-.06-1.369-.185-1.96-.487a5 5 0 0 1-2.186-2.185c-.302-.592-.428-1.233-.487-1.961C3 15.6 3 14.727 3 13.643v-3.286c0-1.084 0-1.958.058-2.666.06-.729.185-1.369.487-1.961A5 5 0 0 1 5.73 3.545c.556-.284 1.154-.411 1.827-.475C8.22 3.007 9.021 3 10 3A1 1 0 0 1 11 4"></path></svg>
                        </div>
                    </div>
                </div>
                {/* <div className={styles.titleBox}>
                    <div className={styles.title}>对话历史</div>
                    <img onClick={createNewChat} src="chat/add.png" alt="" />
                </div> */}
                {/* <h2 className={styles.time}></h2> */}
                {!isSidebarCollapsed && (<div className={styles.sliderline}></div>)}
                {!isSidebarCollapsed && <div className={styles.fileBox}>
                    {!isShowCode ? <div className={styles.fileList}>
                        {chatList.map((item: any, index: number) => {
                            return (
                                <div key={index} className={styles.fileItem}>
                                    <div onClick={() => { getChatLog(item.thread_id); setCurrentList(item.thread_id) }} title={item.thread_name ? item.thread_name : '未知'} className={`${styles.fileTitle} ellipsis ${item.thread_id == currentList ? styles.active : ''}`} key={item.uuid}>
                                        {item.thread_name ? item.thread_name : '新对话'}
                                    </div>
                                    <span onClick={() => { deleteChat(item.uuid) }} className={styles.delete}></span>
                                </div>
                            )
                        })}
                    </div> : <div className={styles.repository}>
                        <div className={styles.fullName}>仓库名称：<span title={codeData.repo_full_name}>{codeData.repo_full_name}</span></div>
                        <div className={styles.branchName}>当前分支：<span title={codeData.branch_name}>{codeData.branch_name}</span></div>
                        <div className={styles.fileTree}>
                            <CodeTree
                                thread_id={router.query.thread_id as string}
                                branch_name={codeData.branch_name}
                                repo_full_name={codeData.repo_full_name}
                                onSelect={handleSelect}
                                treeData={treeData}
                                disabled={isoutputing}
                            />
                        </div>
                    </div>}
                </div>}
                <div className={styles.smallImageContainer}>
                    <img src="customer.png" alt="Small decorative image" className={styles.smallImage} />
                </div>
                <div>
                    <p className={styles.customerText}>扫码免费领不限量使用机会/进群交流</p>
                </div>
                <div className={styles.line}></div>
                <div className={styles.userInfo}>
                    <div className={styles.btnBox} title={"设置"}>
                        <div
                            onClick={() => { setSettingShow(!settingShow); setVisit_page(0); setIsOpen(false) }}
                            className={`${styles.setting} ${theme === 'light' ? styles.light : ''}`}
                        >
                        </div>
                    </div>
                    <div className={styles.infoContent}>
                        <p className={styles.money} >{userInfo.accaccount_type_name}</p>
                        {userInfo.subscription_expired && <p className={styles.money} >|</p>}
                        {!userInfo.subscription_expired && <p className={styles.slide} >|</p>}
                        {userInfo.subscription_expired && <p
                            className={styles.email}>{userInfo.subscription_expired ? '截止至：' : ''}{userInfo.subscription_expired}</p>}
                        {!userInfo.subscription_expired && <p className={styles.upgrade} onClick={() => { setSettingShow(!settingShow); setVisit_page(1) }}>升级到会员</p>}
                    </div>

                    <div className={styles.btnBox} title={"登出"}>
                        <div onClick={() => { Router.push('/login'); localStorage.removeItem('token') }}
                            className={`${styles.exitBtn} ${theme == 'light' ? styles.light : ''}`}></div>
                    </div>
                </div>
            </div>
            {!settingShow && <div className={styles.chatpdfBox}>
                {!chatpdfTips && <div className={styles.chatpdfLine} ref={chatContainerRef} onScroll={handleScroll}>
                    {chatContent.map((item: any, index: number) => {
                        return (
                            <div key={index}>
                                {item.user_type == 'user' && item.text && <div key={item.uuid ? item.uuid : index} className={styles.chatpdfRow}>
                                    <div className={styles.chatpdfAsk}>
                                        {item.file_name && <div className={styles.fileBox}>
                                            <div className={styles.file}>
                                                <div className={styles.fileBg} />
                                                <span className={styles.fileName} title={item.file_name}>{item.file_name}</span>
                                            </div>
                                        </div>}
                                        <div className={styles.askBox}>
                                            <div className={styles.chatAskPdfContent}><pre>{item.text}</pre></div>
                                            <div className={styles.chatpdfIcon}></div>
                                        </div>

                                    </div>
                                </div>}
                                {item.user_type == 'assistant' && item.text && <div key={item.uuid ? item.uuid : index} className={styles.chatpdfRow}>
                                    <div className={styles.chatpdfIcon}></div>

                                    {item.text != '当前对话不存在' && item.text != '订阅已到期' && item.text != '您的今日试用对话机会已用完，请明天继续使用，或者进行会员订阅' && <div className={styles.chatpdfContent}>

                                        {item.text === 'loading' && (!cancleTag ? (
                                            <div className={styles.loadingContainer}>
                                                <img style={{ width: '30px' }} src='/loading/loading2.gif' alt="loading" />
                                                <p className={styles.thinkingText}>Eagle正在思考中</p>
                                            </div>
                                        )
                                            : <p style={{ color: 'red' }}>已取消</p>)}
                                        {item.text !== 'loading' && <TypingEffect ref={typeRef} stop={stop} setMessages={setMessages} giToBottom={giToBottom} setStop={resetFn} thread_id={item.thread_id}
                                            text={item.text} speed={10} />}
                                        {!stop && item.text !== 'loading' && <div className={styles.toolBtns}>
                                            <div className={styles.copy} title='复制' onClick={() => handleCopy(item.text)} >
                                                <div className={styles.copyIcon} />
                                            </div>
                                        </div>}
                                    </div>}
                                    {(item.text === '当前对话不存在' || item.text === '订阅已到期' || item.text === '您的今日试用对话机会已用完，请明天继续使用，或者进行会员订阅')
                                        && <div className={styles.chatpdfContent}><p style={{ color: 'red' }}>{item.text}</p></div>}

                                </div>}

                            </div>
                        )
                    })}

                </div>}
                {/* 新建对话模块显示组件 */}
                {chatpdfTips && <ChatCreatContent handleClick={(tip) => { setChatpdfTips(false); setText(tip); markdown.current = tip; }} />
                }
                {/* 底部对话输入模块 */}
                <div className={styles.chatBottom}>
                    <ChatTextArea text={text} fileName={fileName} onUpload={uploadFile} handleInputChange={handleInputChange} cancleFn={cancleFn} keyupFun={keyupFun} createChat={createChat} stop={stop} upLoading={upLoading}></ChatTextArea>
                    <BottomLayer className={styles.tips} suffix={"人工智能生成内容仅供参考，使用前请校验内容的正确性。"} />
                </div>

            </div>}
            {settingShow && <Setting visit_page={visit_page} getInfo={getInfo} userInfo={userInfo} setIsLoading={setIsLoading}></Setting>}
            {
                isLoading && <Loading></Loading>
            }
            <Modal isOpen={isOpen} onClose={() => { setIsOpen(false) }} title={selectNode?.path || ''}><CodePreview thread_id={router.query.thread_id as string} path={selectNode?.path || ''} branch_name={codeData.branch_name} repo_full_name={codeData.repo_full_name} /></Modal>
        </div >
    )
}
