import React, { useRef } from 'react'
import styles from './upLoad.module.scss'
import { useTheme } from '../../context/ThemeContext';
import classNames from 'classnames';
export const accept = [
    ".zip",
    ".xlsx",
    ".xls",
    ".csv"
]
export default function UpLoad(props: {
    disabled?: boolean
    onChange: (e: any) => void
}) {
    const { theme } = useTheme();
    const upLoad = useRef<any>()
    const uploadFile = (e: any) => {
        e.preventDefault();
        props.onChange(e)
        e.target.value = ''
    }
    const handleClick = () => {
        upLoad?.current && upLoad?.current?.click()
    }
    return (
        <>
            <div className={styles.fileUpload}>
                <div className={classNames(styles.upload, theme == 'light' ? styles.icon_light : styles.icon)} onClick={handleClick} />
                <input disabled={!!props?.disabled} type='file' ref={upLoad} className={styles.file} onChange={uploadFile} accept={accept.join(',')}></input>
            </div>

        </>
    )
}
